module.exports = {
    url: {
        chirpleyApi: 'https://api.chirpley.ai/v1',
        api: `https://api.chirppad.ai/api`,
        login: `https://launchpad.chirppad.ai`,
        main: `http://launchpad.chirppad.ai`,
        site: `https://chirppad.ai`,
        chirpley: `https://v3.chirpley.ai`,
        cdn: `https://cdn.chirpley.ai`,
    },
    cookiedomain: "launchpad.chirppad.ai",

    rules: {
        required: [(v) => !!v || 'Required field!'],
        name: [(v) => !!v || 'Required field!', (value) => (value && !(value.length > 100)) || 'Maximum 100 characters'],
        email: [(v) => !!v || 'Email address is not valid!', (v) => /.+@.+\..+/.test(v) || 'Invalid email address!'],
        phonenumber: [(v) => !!v || 'Phone number is not valid!', (v) => /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g.test(v) || 'Invalid phone number!'],

    },
    regex: {
        name: /^[a-zA-Z -]{2,50}$/,
        mail: /^.+@.+$/,
        phonenumber: /^\+?[1-9]\d{3,14}$/,
        password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\s\S])[ -~]{8,64}$/,
    },
    allowedredirectdomains: [
        'api.chirppad.ai',
        'chirppad.ai',
        'launchpad.chirppad.ai',
        'v3.chirpley.ai',
        'chirpley.ai',
        '*.chirpley.ai',
        '*.chirppad.ai',
        'localhost:8080',
        'localhost:8081',
        'localhost:8082',
        'localhost:8083',
        'localhost:8084',
        'localhost:8085',
        'localhost:8086',
    ],
    dateformat: {
        weekday: 'short',
        year: 'numeric',
        day: 'numeric',
        month: 'long',
        hour: 'numeric',
        minute: 'numeric'
    },
    auth: {
        maps: "AIzaSyB30vQq6BQslpi5frf6YMxoCKo1A2lIAoI"
    },
    blockpass:{
        client_id: "chirppad_ab9cf"
    },
    crypto: {
        hot_wallet_address_mainnet: "0xBbdf5975d0065DD5c1c1b377462c7857D5066870",
        hot_wallet_address_testnet: "0xBbdf5975d0065DD5c1c1b377462c7857D5066870",
        chrp_token_address_mainnet: "0xBbdf5975d0065DD5c1c1b377462c7857D5066870",
        chrp_token_address_testnet: "0xBbdf5975d0065DD5c1c1b377462c7857D5066870",
        active_chain: "mainnet", //testnet / mainnnet
        walletconnect_project_id: "17087225271732fe6ebb20e87f56e41c"
    }
}