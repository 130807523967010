import client from "@/services/client";

export default {
	async createWallet(payload) {
		return client.parseResponse(
			await client.post("wallets", payload.payload, {
				...payload.config,
			})
		);
	},

	async deleteWallet(payload) {
		return client.parseResponse(
			await client.delete(`wallets/${payload.wallet_id}`, {}, payload.payload, {
				...payload.config,
			})
		);
	},

	async setWalletAsDefault(payload) {
		return client.parseResponse(
			await client.post(`wallets/${payload.payload.wallet_id}/default`, payload.payload, {
				...payload.config,
			})
		);
	},
};
