// String Prototype Extensions
Object.defineProperty(String.prototype, "capitalize", {
    value: function () {
        return this.charAt(0).toUpperCase() + this.slice(1);
    },
    enumerable: false,
});

Object.defineProperty(String.prototype, "shortedAddress", {
    value: function () {
        if (this.length < 6) {
            return this;
        }
        const firstTwo = this.slice(0, 2);
        const lastFour = this.slice(-4);
        return `${firstTwo}...${lastFour}`;
    },
    enumerable: false,
});

Object.defineProperty(String.prototype, "transformServiceSlug", {
    value: function () {
        return this.replace(/([a-z]+)-(\w+)-(\w+)/, "$2 - $3");
    },
    enumerable: false,
});

Object.defineProperty(String.prototype, "transformServiceSlugType", {
    value: function () {
        return this.replace(/([a-z]+)-(\w+)-(\w+)/, "$2");
    },
    enumerable: false,
});

Object.defineProperty(String.prototype, "formatWalletShort", {
    value: function () {
        if (!this) return '';
        return `${this.substring(0, 5)}...${this.substring(this.length - 5)}`;
    },
    enumerable: false,
});

Object.defineProperty(String.prototype, "formatFollowers", {
    value: function () {
        if (!isNaN(this)) {
            let subtitleValue = parseInt(this);
            if (subtitleValue <= 100) return "0 - 99 Followers";
            if (subtitleValue <= 1000) return "100 - 999 Followers";
            if (subtitleValue <= 5000) return "1000 - 4999 Followers";
            if (subtitleValue <= 10000) return "5000 - 9999 Followers";
            if (subtitleValue <= 50000) return "10000 - 49999 Followers";
            if (subtitleValue <= 100000) return "50000 - 99999 Followers";
            if (subtitleValue <= 250000) return "100000 - 249999 Followers";
            if (subtitleValue <= 500000) return "250000 - 499999 Followers";
            if (subtitleValue <= 1000000) return "500000 - 999999 Followers";
            if (subtitleValue <= 2000000) return "1000000 - 1999999 Followers";
            return "2000000+ Followers";
        } else {
            return parseInt(this);
        }
    },
    enumerable: false,
});

Object.defineProperty(String.prototype, "formatPlatform", {
    value: function () {
        switch (this.toLowerCase()) {
            case "tkt": return "TikTok";
            case "twt": return "Twitter/X";
            case "ig": return "Instagram";
            case "fb": return "Facebook";
            case "onsite": return "On location";
        }
    },
    enumerable: false,
});

Object.defineProperty(String.prototype, "formatPlatformSlug", {
    value: function () {
        return this.toLowerCase()
            .replace("tkt", "tiktok")
            .replace("twt", "twitter")
            .replace("ig", "instagram")
            .replace("fb", "facebook")
            .replace("onsite", "onsite");
    },
    enumerable: false,
});

Object.defineProperty(String.prototype, "formatFullPlatformSlug", {
    value: function () {
        return this.toLowerCase()
            .replace("tiktok", "tkt")
            .replace("twitter", "twt")
            .replace("instagram", "ig")
            .replace("facebook", "fb")
            .replace("onsite", "onsite");
    },
    enumerable: false,
});

Object.defineProperty(String.prototype, "getFileType", {
    value: function () {
        const val = this.toLowerCase();
        const imageTypes = {
            gif: "image/gif", jpeg: "image/jpeg", jpe: "image/jpeg",
            jpg: "image/jpeg", png: "image/png"
        };
        const videoTypes = {
            mpeg: "video/mpeg", mpe: "video/mpeg", mpg: "video/mpeg",
            mp4: "video/mp4", m4v: "video/mp4", mov: "video/quicktime"
        };
        const audioTypes = { mp4: "audio/mpeg", wav: "audio/wav" };

        if (imageTypes[val]) return imageTypes[val];
        if (videoTypes[val]) return videoTypes[val];
        if (audioTypes[val]) return audioTypes[val];
    },
    enumerable: false,
});

// Array Prototype Extensions
Object.defineProperty(Array.prototype, "getPreview", {
    value: function () {
        if (this.length > 0) {
            const firstItem = this[0];
            if (firstItem instanceof Blob || firstItem instanceof File) {
                return URL.createObjectURL(firstItem);
            }
        }
        return null; // Return null if the array is empty or the first item is not a Blob or File
    },
    enumerable: false,
});

// Object Prototype Extensions
Object.defineProperty(Object.prototype, "getOwnPropertyDescriptors", {
    value: function () {
        const obj = JSON.parse(JSON.stringify(this));
        Object.keys(obj).reduce((acc, key) => {
            if (acc["_" + key] === acc[key]) {
                delete acc["_" + key];
            }
            return acc;
        }, obj);
        return obj;
    },
    enumerable: false,
});

// Number Prototype Extensions
Object.defineProperty(Number.prototype, "timeAgo", {
    value: function () {
        const epochTime = this;
        if (Number.isNaN(epochTime)) return `0s`;

        const now = Math.floor(Date.now() / 1000);
        const diffSeconds = now - epochTime;

        const diffMinutes = Math.floor(diffSeconds / 60);
        const diffHours = Math.floor(diffMinutes / 60);
        const diffDays = Math.floor(diffHours / 24);

        if (diffDays > 0) return `${diffDays}d ${diffHours % 24}h`;
        if (diffHours > 0) return `${diffHours}h`;
        if (diffMinutes > 0) return `${diffMinutes}m`;
        return `${diffSeconds}s`;
    },
    enumerable: false,
});

Object.defineProperty(Number.prototype, "convertUnixToDate", {
    value: function () {
        const date = new Date(this * 1000);
        const day = ("0" + date.getDate()).slice(-2);
        const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero-based
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    },
    enumerable: false,
});

Object.defineProperty(Number.prototype, "getOrdinal", {
    value: function () {
        const lastDigit = this % 10;
        const lastTwoDigits = this % 100;

        if (lastTwoDigits >= 11 && lastTwoDigits <= 13) return this + "th";
        if (lastDigit === 1) return this + "st";
        if (lastDigit === 2) return this + "nd";
        if (lastDigit === 3) return this + "rd";
        return this + "th";
    },
    enumerable: false,
});
