import { defineStore } from "pinia";
import api from "@/stores/api";
import { toast } from "vue3-toastify";

const cookies = require("js-cookie");

export const useAuthStore = defineStore("auth", {
	state: () => ({
		user: {
			roles: [],
			users: [],
		},
	}),
	getters: {
		loggedIn() {
			return !!cookies.get("chirpadToken");
		},
	},
	actions: {
		async authenticate(payload) {
			return await api.authenticate(payload);
		},

		async isSocialPlatformAlive(payload) {
			try {
				return await api.isSocialPlatformAlive(payload);
			} catch (err) {
				toast.error(err.response.data.message);
			}
		},
		async getProfile(payload) {
			try {
				const { data } = await api.getProfile(payload);
				this.user = data;
			} catch (err) {
				toast.error(err.response.data.message);
			}
		},
		async getUserBalance(payload) {
			try {
				this.users = await api.getUserBalance(payload.payload, payload.config);
			} catch (err) {
				toast.error(err.response.data.message);
			}
		},
		async createUserWallet(payload) {
			try {
				const { data } = await api.createWallet(payload);
				this.user = data.user;
				return data;
			} catch (err) {
				toast.error(err.response.data.message);
			}
		},
		
		
	},
});
